@import "../../scss/variables";
@import "../../scss/functions";
@import "../../scss/mixins/hacks";
@import "../../scss/mixins/direction";


$local-transition-duration: 0.25s;
$local-open-transition-duration: 0.2s;

.departments {
  width: 294px;
  height: 60px;
  color: $departments-font-color;
  position: relative;
}

.departments__button {
  border: none;
  background: transparent;
  color: inherit;
  font-size: 16px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: default;
  font-weight: $font-weight-medium;
  padding: 0;

  @include direction {
    #{$padding-inline-start}: 16px;
    padding-bottom: 2px;
    text-align: $inline-start;
  }

  &:focus {
    outline: none;
  }
}

.departments__button-icon {
  position: absolute;
  top: calc(50% - 7px);
  fill: #fff;
  transition: fill 0.2s;

  @include direction {
    #{$inset-inline-start}: 16px;
  }
}

.departments__button-arrow {
  position: absolute;
  top: calc(50% - 3px);
  fill: $departments-icon-color;
  transition: fill 0.2s, transform 0.2s;

  @include direction {
    #{$inset-inline-end}: 13px;
  }
}

.departments__body {
  width: 100%;
  padding-top: $departments-height;
  position: absolute;
  background: #ffffff;
  color: #333333;
  box-shadow: $departments-shadow;
  border-radius: 2px;
  top: 0;
}

.departments__links {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  position: absolute;
  width: 100%;

  list-style: none;
  padding: 0;
  margin: 0;
}

.departments__item__li {
  &:hover .departments__menu,
  &:hover .departments__megamenu {
    display: block;
  }

  &:hover > a {
    background: $departments-item-hover-bg;
  }
}

.departments__item__li_a {
  font-weight: $departments-font-weight;
  position: relative;
  padding: 10px 16px;
  color: inherit;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.departments__item--menu {
  position: relative;
  flex-direction: column;
}

.departments__item {
  display: flex;
  flex-direction: column;
}

.departments__border {
  width: 93%;
  height: 1px;
  background: #f5f4f4;
  margin: 0 auto;
}

.departments__link-arrow {
  top: calc(50% - 5px);
  fill: $departments-arrow-color;

  @include direction {
    #{$inset-inline-end}: 14px;
    transform: scaleX(1 * $transform-direction);
  }
}

.departments__megamenu {
  position: absolute;
  top: 0;
  height: 100%;
  display: none;
  @include direction {
    #{$inset-inline-start}: 100%;
  }
}

.departments__menu {
  position: fixed;
  top: 0;
  height: 100%;
  display: none;
  @include direction {
    #{$inset-inline-start}: 100%;
  }
}

.departments__megamenu--sm {
  width: (206px * 1 + 31px);
}

.departments__megamenu--nl {
  width: (206px * 2 + 31px);
}

.departments__megamenu--lg {
  width: (206px * 3 + 31px);
}

.departments__megamenu--xl {
  width: (206px * 4 + 31px);
}

.departments__links-wrapper {
  overflow: hidden;
  height: 0;
  opacity: 0;
}

.departments:not(.departments--fixed) {
  .departments__button {
    cursor: pointer;

    &:hover {
      .departments__button-icon,
      .departments__button-arrow {
        fill: currentColor;
      }
    }
  }
}

.category-title-fms {
  font-size: 18px;
  padding-left: 10px;
}

.departments {
  .departments__body {
    background: transparent !important;
  }
}

.departments--opened {
  .departments__links-wrapper {
    overflow: visible;
    height: auto;
    opacity: 1;
  }

  .departments__button-arrow {
    transform: rotateZ(180deg);
  }
}

.departments--transition {
  .departments__links-wrapper {
    overflow: hidden;
  }
}

.departments__button {
  padding-left: 0 !important;
  display: flex;
  align-items: center;
}

#savings_fm_id {
  cursor: pointer;
}

.departments__item--menu {
  position: relative;
  flex-direction: column;
}

.departments__item {
  display: flex;
  flex-direction: column;
}

.departments__item,
.departments__item--menu {
  overflow: visible !important;
}

.departments__item--menu {
  display: flex;

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.mobileMenuFooter-fms {
  padding: 0 10px;
  background: rgba(13, 71, 161, 0.05);

  .mobile-footer-dropdown {
    .mobile-footer-dropdown-divs {
      .topBar-dropdown--opened {
        min-height: auto !important;
      }

      .topBar-dropdown__btn {
        fill: #999;

        .lang-name-image {
          .lang-name {
            margin: 0;
            font-size: 16px;
            font-family: "Open Sans", sans-serif;
          }

          .currency {
            margin-left: 22px;
          }

          img {
            margin: auto 10px auto 0;
          }

          .lang-mobile-img {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .menu {
    width: unset;
    box-shadow: unset !important;
    background: unset;

    .menu__icon {
      position: unset;
      padding-right: 10px;
    }

  }

  .topBar-dropdown__body {
    right: unset;
    transition: unset !important;

    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0 !important;
    }
  }

  .topBar-dropdown__btn {
    padding-left: unset !important;

    span {
      display: block;
    }

    .mobileversion-lang {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding-left: 3px;

      span {
        margin-left: 10px;
        padding: 0 !important;
      }
    }
  }

  .null-icon-fms {
    margin-left: unset;
  }

  .topBar-dropdown__btn {
    svg {
      margin-right: 10px;
      transition: transform 0.3s;
    }
  }

  .footer-newsletter__social-links {
    margin-top: unset;
  }

  .mobile-footer-dropdown-divs {
    min-height: 60px;
    border-bottom: 1px solid white;
    font-size: 15px;
    color: #3d464d;
    display: flex;
    align-items: center;

    .lang-name-image {
      flex-direction: row-reverse;
    }

    .footer-contacts {
      .footer-title.opened {
        margin-top: 19px;
      }
    }
  }

  .mobile-footer-dropdown-last-divs {
    min-height: 60px;
    display: flex;
    align-items: center;
  }

  .topBar-dropdown,
  .site-footer__widget,
  .site-footer__widget {
    width: 100%;
  }

  .topBar-dropdown,
  .footer-newsletter__social-links {
    padding: 0 10px;
  }

  .footer-title {
    padding: 0 10px;
    margin-right: 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;

    .contacts-path-fms {
      margin-right: 10px;

      path {
        fill: #3d464d !important;
      }
    }

    .mobile-footer-arrow {
      transition: transform 0.3s;
    }
  }

  .footer-contacts__contacts {
    padding: 0 40px;

    p, a {
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      width: 100%;
      word-wrap: break-word;
    }
  }

  .topBar-dropdown--opened {
    .topBar-dropdown__btn {
      margin-top: 22px;
    }

    .topBar-dropdown__body {
      padding-top: unset;
    }

    .menu {
      margin-bottom: 0;
    }
  }

  .social-links__item {
    margin-right: 15px !important;
  }
}

.topBar-dropdown {
  height: 100%;
  position: relative;
}

.topBar-dropdown--opened {
  .topBar-dropdown__body {
    z-index: 201;
    transition-delay: 0s, 0s, 0s;
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
  }
}

.topBar-dropdown__btn {
  height: 100%;
  width: 100%;
  border: none;
  padding: 0;
  cursor: pointer;
  background: $topbar-dropdown-bg;
  color: $topbar-dropdown-font-color;
  fill: $topbar-dropdown-arrow-color;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:focus {
    outline: none;
  }
}

.topBar-dropdown--opened .topBar-dropdown__btn,
.topBar-dropdown__btn:hover,
.topBar-dropdown__btn:focus {
  .topBar__item-value {
    color: $topbar-dropdown-hover-value-color;
  }
}

.topBar-dropdown__body {
  position: absolute;
  z-index: 201 !important;
  top: 100%;
  right: -25px;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform $local-transition-duration,
  opacity $local-transition-duration, visibility 0s $local-transition-duration;

  @include direction {
    #{$inset-inline-end}: 0;
  }
}

.topBar-dropdown__body-position {
  position: unset;
  z-index: 201 !important;
  top: 100%;
  right: -25px;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform $local-transition-duration,
  opacity $local-transition-duration, visibility 0s $local-transition-duration;

  @include direction {
    #{$inset-inline-end}: 0;
  }
}

.lang-name-image {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;

  p {
    margin: 0;
    margin-right: 10px;
  }

  div {
    div {
      display: block !important;
    }
  }
}

.rotT_fms {
  transform: rotate(180deg);
}

.dropcart {
  font-size: 15px;
  width: 320px;
  font-weight: $font-weight-normal;
  color: $dropcart-font-color;
  background: $dropcart-bg;
  box-shadow: $dropcart-shadow;
}

.dropcart__empty {
  padding: 42px 30px;
  text-align: center;
  min-height: max-content;
  max-height: 290px;
  overflow-y: auto;
}

.dropcart__products-list_scroll {
  padding: 20px 16px;
  text-align: center;
  min-height: max-content;
  max-height: 290px;
  overflow-y: scroll;
}

.dropcart__products-list {
  padding: 20px 16px;
  min-height: max-content;
  max-height: calc(100vh - 330px);
}

.dropcart__totals {
  border-top: 1px solid $dropcart-divider-color;
  line-height: 24px;
  padding: 16px 20px 0;

  th {
    @include direction {
      #{$padding-inline-end}: 16px;
    }
  }

  td {
    @include direction {
      text-align: $inline-end;
    }
  }

  table {
    width: 100%;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.dropcart__buttons {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin-top: 4px;
  padding: 20px;
  justify-content: space-between;

  > * {
    flex-grow: 1;
    font-size: 15px;
  }

  > :first-child {
    @include direction {
      #{$margin-inline-end}: 8px;
    }
  }

  a {
    width: 135px;
    display: block;

    & span {
      text-overflow: ellipsis;
      display: block;
      text-align: center;
    }
  }
}

.dropcart__buttons-link {
  white-space: nowrap;
  overflow: hidden;
}

.rounded-pills {
  color: white;
  width: 100%;
  border-radius: 5px !important;
  text-align: center;
}

.cart-icon {
  padding-right: unset !important;
  margin-right: 0;

  path {
    stroke: $header-icon-fill-stroke-color;
  }

  .indicator__value {
    top: -12px;
  }
}

.dropcart__product-image {
  width: 56px;
  flex-shrink: 0;
  margin-right: 14px;
}


.cart-table__column--image .product-image__body {
  box-shadow: none;
  position: absolute;
  top: 0;
  left: 0;
}

// FIXME: remove if need , i will add parts of product image in ProductCard.module.scss

.product-image__body {
  display: block;
  position: relative;
  -webkit-box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
  box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
  width: 100%;
  aspect-ratio: 1/1;
  max-height: 359px;

  img {
    width: 100% !important;
    object-fit: contain;
    height: 100%;
    display: block;
  }
}

.product-image__img {
  display: block;
  width: 100%;
  object-fit: contain;
  position: relative;
  padding: 3px 7px;
  max-height: 359px;

  &,
  .owl-carousel .owl-item & {
    @include only-ie {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

// FIXME:check and delete this piece of scss if no need

.product-image--location--gallery {
  .owl-carousel .owl-item & .product-image__img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.product-small-img {
  height: initial !important;
  padding: 5px 0 !important;
}

.cart-table__column.cart-table__column--image {
  .product-small-img {
    height: 100% !important;
  }
}

.dropcart__product-remove {
  margin-top: -6px;
  flex-shrink: 0;

  path {
    fill: black;
  }

  @include direction {
    #{$margin-inline-end}: -6px;
  }
}

.indicator {
  cursor: pointer;
  display: flex;
  color: $indicator-default-font-color;
}

.indicator--trigger--click.indicator--opened,
.indicator:hover {
  .indicator__button {
    color: $indicator-hover-font-color;
  }
}

.indicator--trigger--click.indicator--opened,
.indicator--trigger--hover:hover {
  .indicator__dropdown {
    z-index: 1;
    transition-delay: 0s, 0s, 0s;
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
  }
}

.indicator--trigger--click:not(.indicator--opened) {
  .indicator__icon--open {
    transform: scale(0);
    opacity: 0;
  }
}

.indicator--trigger--click:not(.indicator--opened) {
  .indicator__icon--open {
    transform: scale(0);
    opacity: 0;
  }
}

.indicator__value {
  height: 15px;
  font-size: 10px;
  padding: 0 5px;
  border-radius: 1000px;
  position: relative;
  top: -11px;
  background: $main-theme-color-light;
  color: #fff;
  font-weight: $font-weight-bold;
  margin-left: -5px;
  margin-right: -8px;

  @include direction {
    #{$margin-inline-start}: -5px;
    #{$margin-inline-end}: -8px;
  }
}

.heartButton {
  .indicator__value {
    top: -12px;
  }
}

.indicator__area {
  background: $indicator-default-bg;
  transition: background-color 0.2s;
  border-radius: $indicator-border-radius;
  height: $indicator-height;
  display: flex;
  align-items: center;
  fill: $header-icon-fill-stroke-color;

  path {
    fill: $header-icon-fill-stroke-color;
  }
}

.nav-panel__indicators {
  align-items: center;

  .indicator__area {
    height: unset !important;
  }

  .indicator {
    flex-direction: column;
    align-items: center;
  }

  .indicator-title-fms {
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }

  .account-logo-svg {
    padding-right: unset !important;
  }
}

.indicator__dropdown {
  position: absolute;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform $local-open-transition-duration,
  opacity $local-open-transition-duration,
  visibility 0s $local-open-transition-duration;
  top: 100%;
  bottom: unset;
  right: 0;

  @include direction {
    #{$inset-inline-end}: 0;
  }
}

.account-menu {
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  background: #fff;
  color: #3d464d;
  width: 280px;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  @include only-ie() {
    box-shadow: 0 0 0 1px rgba(#000, 0.15);
  }
}

.account-menu__form {
  padding: 0 30px 32px;

  .form-group {
    margin-bottom: 1rem;
  }
}

.account-menu__form-title {
  text-align: center;
  padding: 20px 0 16px;
  font-weight: 700;
  font-size: 30px;
}

.account-menu_loginAndpass {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  input {
    padding: 12px;
  }

  a {
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    color: #0d47a1;
    margin-top: 8px;
  }
}

.account-menu__form-forgot-link {
  $local-offset: 5px;
  border-radius: 2px;
  font-size: 15px;
  font-weight: $font-weight-medium;
  background: $btn-light-bg-color;
  color: $btn-light-font-color;
  padding: 0 7px;
  transition: background 0.1s, color 0.1s;
  text-decoration: underline;
  display: block;
  margin-top: 30px;
  max-width: max-content;
  width: 100%;

  @include direction {
    #{$inset-inline-end}: $local-offset;
  }

  &:hover {
    background: none;
    color: #000C66;
    text-decoration: underline;
  }

  &:active {
    background: none;
    color: #3d464d;
  }
}

.account-menu__form-button {
  margin-top: 32px;
  text-align: center;

  .btn {
    padding-left: 28px;
    padding-right: 28px;
  }
}

.account-menu__form-link {
  font-size: 14px;
  text-align: center;
  margin-left: unset;
  color: #0d47a1;

  a {
    transition: color 0.1s;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    color: #0d47a1;
    margin-top: 8px;
    text-decoration-line: underline;
  }

  a:hover {
    background: none;
    color: #000C66;
    text-decoration: underline;
  }

  a:active {
    background: none;
    color: #3d464d;
  }
}

.new-custimer {
  font-size: 14px;
  text-align: center;
  color: #2f2f2f;
  margin-right: 10px;
}

.account-menu__divider {
  height: 1px;
  background: $account-menu-divider-color;
}

.account-logo-svg {
  fill: #ffffff;
}

.indicator__button {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  color: inherit;
  fill: #fff;

  &:focus {
    outline: none;
  }

  &:hover {
    color: inherit;
  }
}

.indicator__icon {
  transition: transform 0.2s, opacity 0.2s;
}

.indicator__icon--open {
  position: absolute;
}

.menu {
  color: $menu-font-color;
  background: $menu-bg;
  box-shadow: $menu-shadow;
  list-style: none;

  width: 190px;
  padding: 4px 0;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  font-weight: $font-weight-medium;

  @include only-ie() {
    box-shadow: 0 0 0 1px rgba(#000, 0.15);
  }
}

.menu__li {
  position: relative;

  &:hover > .menu__li__a {
    background: $menu-item-hover-bg;
  }

  &:hover > .menu__submenu {
    display: block;
    transform: rotateY(0deg);
    animation: fadeIn .2s
  }

  &:hover > button {
    background: $menu-item-hover-bg;
  }

  > button {
    background: transparent;
    border: none;
    width: 100%;
    font-weight: inherit;
    display: flex;
    padding: 8px;
    color: inherit;
    justify-content: space-between;
    align-items: center;

    @include direction {
      text-align: $inline-start;
      #{$padding-inline-end}: 24px;
      #{$padding-inline-start}: 16px;
    }

    &:focus {
      outline: none;
    }
  }
}

.menu__li__a {
  background: transparent;
  border: none;
  width: 100%;
  font-weight: inherit;
  display: flex;
  padding: 8px;
  color: inherit;
  justify-content: space-between;
  align-items: center;
}

.menu--layout--topBar {
  padding: 6px 0;
  width: 150px;
  box-shadow: $menu-topbar-shadow;

  @include only-ie() {
    box-shadow: 0 0 0 1px rgba(#000, 0.15);
  }

  > li > a,
  > li > button {
    display: block;
    color: inherit;

    @include direction {
      #{$padding-inline-end}: 21px;
      #{$padding-inline-start}: 13px;
    }
  }

  &.menu--with-icons {
    .menu__icon {
      position: absolute;
      top: 3px;

      @include direction {
        #{$inset-inline-start}: 12px;
      }
    }

    > li > a,
    > li > button {
      @include direction {
        #{$padding-inline-start}: 13px + 24px + 8px;
      }
    }
  }
}

.menu--layout--topBar.menu--with-icons {
  .dropdown-submenu-items {
    padding-left: 40px;
  }
}

.menu--with-icons {
  > li > a,
  > li > button {
    @include direction {
      #{$padding-inline-start}: 16px + 24px + 10px;
    }
  }
}

.menu__arrow {
  top: calc(50% - 4px);
  fill: $menu-item-arrow-color;

  @include direction {
    #{$inset-inline-end}: 12px;
    transform: scaleX(1 * $transform-direction);
  }
}

.menu__submenu {
  top: 0;
  position: absolute;
  left: 187px;
  transform: rotateY(45deg);
  transition: transform 0.2s, opacity 0.2s;
  display: none;

  @include direction {
    #{$inset-inline-start}: 100%;
    transform-origin: #{$inline-start};
  }
}

.menu__icon {
  position: absolute;
  top: 3px;
  margin-top: 6px;

  div {
    width: 24px;
    height: 16px;
  }

  @include direction {
    #{$inset-inline-start}: 15px;
  }
}

.submenu__item_name {
  padding-left: 10px;
}

.nav-links__menu,
.nav-links__megaMenu {
  position: absolute;
  transform-origin: top;
  transform: rotateX(45deg);
  display: none;
}

.nav-links__item--menu {
  position: relative;
}

.nav-links__item--with-submenu {
  & > a span {
    position: relative;
  }

  & > a svg {
    top: calc(50% - 3px);
    margin-left: 6px;

    @include direction {
      #{$inset-inline-end}: 12px;
    }
  }
}

.nav-links__item {
  height: 100%;
  z-index: 10;
  margin-left: 42px;
  cursor: pointer;

  & > a {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px;
    line-height: 1;
    letter-spacing: .02em;

    color: $nav-links-item-default-font-color;

    span {
      background: $nav-links-item-default-bg;
      box-shadow: $nav-links-item-default-shadow;
    }

    svg {
      fill: $nav-links-item-default-arrow-color;
    }

    & > span {
      display: flex;
      align-items: center;
      background: transparent;
      transition: background-color .2s;
      height: $nav-links-item-height;
      border-radius: $nav-links-item-border-radius;
    }
  }

  &:hover > a {
    color: $main-theme-color-light;

    span {
      background: $nav-links-item-hover-bg;
      box-shadow: $nav-links-item-hover-shadow;
    }

    svg {
      fill: $nav-links-item-hover-arrow-color;
    }

    & > span {
      transition-duration: .1s;
    }
  }

  &:hover .nav-links__menu,
  &:hover .nav-links__megaMenu {
    transform: rotateX(0deg);
    display: block;
    animation: fadeIn .2s;
  }
}

.nav-links__list {
  height: inherit;
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 16px;
  font-weight: 400;
  color: #707070;
  padding: 0;
  margin: 0;
}

.nav-panel__logo svg {
  display: block;
  fill: $nav-panel-logo-color;
}

.nav-panel__logo + .nav-panel__nav-links,
.nav-panel__departments + .nav-panel__nav-links {
  @include direction {
    #{$margin-inline-start}: 18px;
  }
}

.nav-panel__item_row {
  margin-left: 15px;
}

.nav-panel__departments {
  flex-shrink: 0;
}

.nav-panel__departments .departments--opened .departments__links {
  display: block;
  border: 1px solid #f5f4f4;
  border-radius: 4px;
  max-height: 425px;
  overflow-x: hidden;
  overflow-y: auto;
}

.departments__links {
  display: none;
}

.site-header__search {
  flex-grow: 1;
  display: flex;
}

.site-header__middle {
  .site-header__search {
    justify-content: flex-end;
  }
}

.nav-panel__indicators {
  display: flex;
  height: 100%;
  justify-content: center;

  @include direction {
    #{$margin-inline-start}: auto;
  }
}

.nav-panel {
  height: 100%;
  background: $navpanel-background;
  box-shadow: $nav-panel-shadow;
  color: $nav-panel-font-color;
}

.nav-panel--stuck {
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: $nav-panel-stuck-shadow;
}

.nav-panel__container {
  height: 100%;
}

.nav-panel__row {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.site-header {
  background: $header-bg;
  color: $header-font-color;
  position: sticky;
  background: #fff;
  z-index: 200;
  //height: 130px;
  top: 0;
  box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.1);
}

.site-header__middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}


.header__logo {
  min-width: 200px;
  height: 100%;
  display: flex;
  align-items: center;

  span {
    position: relative !important;
    min-width: 200px !important;
    height: 50px !important;
  }

  img {
    display: block;
  }

  @media (max-width: 786px) {
    span {
      height: 20px !important;
    }
  }
}

.site-header__nav-panel {
  height: 60px;
}

.topBar__item {
  height: 100%;
  display: flex;
  align-items: center;
}

.topBar {
  height: 40px;
  background: $topbar-bg;
  box-shadow: $topbar-shadow;
  font-size: 14px;
  color: $topbar-font-color;
}

.topBar__container {
  height: 100%;
}

.topBar__row {
  height: 100%;
  align-items: center;
  display: flex !important;
  justify-content: space-between;
}

.language-adjust {
  display: flex;
  height: 100%;
  width: max-content;
  align-content: center;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .departments {
    width: 210px;
  }

  .departments__megamenu--sm {
    width: (172px * 1 + 32px);
  }

  .departments__megamenu--nl {
    width: (172px * 2 + 32px);
  }

  .departments__megamenu--lg {
    width: (172px * 3 + 32px);
  }

  .departments__megamenu--xl {
    width: (172px * 4 + 32px);
  }

  .nav-links__item--with-submenu > a svg {
    @include direction {
      #{$inset-inline-end}: 8px;
    }
  }
}

@media screen and (max-width: 991px) {
  .lang-mobile-img_level_1 {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  }

  .lang-name-image {
    p {
      margin: 0;
      margin-left: 10px;
    }
  }

  .topBar-dropdown {
    span {
      display: none;
    }

    .topBar-dropdown__btn {
      justify-content: space-between;
      display: flex;
      padding-right: unset;
      padding-left: 10px;

      span {
        padding-left: 10px;
        padding-bottom: unset !important;
      }
    }

    .topBar-dropdown__body {
      padding-top: 10px;
      padding-right: unset;
    }
  }

  .topBar-dropdown--opened {
    min-height: 100px !important;
  }

  .topBar-dropdown--opened .menu {
    margin-left: 20px;
  }

  .topBar-dropdown__body-position {
    position: unset;
    z-index: 201 !important;
    top: 100%;
    right: -25px;
    visibility: hidden;
    transform-origin: top;
    transform: rotateX(60deg);
    opacity: 0;
    transition: transform $local-transition-duration,
    opacity $local-transition-duration, visibility 0s $local-transition-duration !important;

    @include direction {
      #{$inset-inline-end}: 0;
    }
  }

  .newclass {
    position: static !important;
  }

  .account-menu__form-forgot-link {
    margin-top: 15px;
  }

  .account-menu__form-button {
    margin-top: 25px;
  }

  .account-logo-svg {
    fill: #3d464d;
  }

  .mobile-header__body {
    .mobile-header__menu-button {
      width: 48px;
      justify-content: flex-start !important;
    }

    .indicator__button {
      width: 48px;
      justify-content: flex-end;
      padding-right: unset !important;
    }
  }

  .mobile-header__body {
    .mobile-header__menu-button {
      width: 48px;
      justify-content: flex-start !important;
    }

    .indicator__button {
      width: 48px;
      justify-content: flex-end;
      padding-right: unset !important;
    }
  }

  .dropdown-submenu-items {
    display: inline-block;
    padding-left: 30px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  }

  .search-product-image__body-fms {
    min-height: 10px !important;
  }

  .header__logo {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .cart-table__column {
    padding: 12px 18px;
    border-top: 1px solid $table-border-color;

    &:first-child {
      @include direction {
        #{$border-inline-start}: 1px solid $table-border-color;
      }
    }

    &:last-child {
      @include direction {
        #{$border-inline-end}: 1px solid $table-border-color;
      }
    }
  }

  .cart-table__head {
    background: $table-th-bg;
    font-size: 18px;

    .cart-table__column {
      font-weight: 600;
      font-size: 16px;
    }

    .cart-table__column:first-child {
      @include direction {
        #{$border-start-start-radius}: $table-border-radius;
      }
    }

    .cart-table__column:last-child {
      @include direction {
        #{$border-start-end-radius}: $table-border-radius;
      }
    }
  }

  .cart-table__body {
    .cart-table__column {
      padding-top: 18px;
      padding-bottom: 18px;
      height: 120px;
    }

    .cart-table__row:last-child {
      .cart-table__column {
        border-bottom: 1px solid $table-border-color;
        position: relative;
      }

      .cart-table__column:first-child {
        @include direction {
          #{$border-end-start-radius}: $table-border-radius;
        }
      }

      .cart-table__column:last-child {
        @include direction {
          #{$border-end-end-radius}: $table-border-radius;
        }
      }
    }
  }

  .cart-table__column--image {
    width: 1px;
    text-align: center;

    @include direction {
      #{$padding-inline-end}: 4px;
    }

    .product-image {
      width: 80px;
    }
  }

  .cart-table__column--price {
    @include direction {
      text-align: $inline-end;
    }
  }

  .cart-table__column--quantity {
    text-align: center;
    width: 1px;

    @include direction {
      #{$padding-inline-start}: 90px;
    }
  }

  //.column--total-fms {
  //  text-align: center !important;
  //}

  .cart-table__column--total {
    max-width: 170px;
    text-align: end;

    @include direction {
      text-align: $inline-end;
    }
  }

  .cart-table__column--remove {
    width: 1px;
  }

  .cart-table__column--image {
    padding: 20px 20px 12px;
    display: inline-block;

    .product-image {
      width: 100%;
      max-width: 80px;
      margin: 0;
      display: inline-block;
    }
  }
}


