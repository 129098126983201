.cross__prods {
  span {
    position: relative !important;
  }

  img {
    position: unset !important;
    width: unset !important;
    height: auto !important;
    max-width: unset !important;
    min-width: unset !important;
    min-height: unset !important;
  }
}

.crossSell-content {
  padding: 0;

  .product-card {
    width: 47%;
  }

  .cross-sell-button {
    display: flex;
    align-items: center;
    gap: 20px;

    .cross_btn {
      flex: 1 !important;

      .product-card__addToCart {
        width: 100%;
        font-size: 13px;
        margin: 0;
        height: 40px;
        border-radius: 8px;
      }
    }

    .product__actions-item {
      margin: 0;
      flex: 1 !important;

      .product__quantity {
        width: 100% !important;
      }

      input {
        border: 1px solid #707070;
        border-radius: 8px;
        margin: 0;
      }
    }
  }

  .crossel_body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    gap: 40px;

    .product-card {
      .product-card__image.cross__prods {
        img {
          max-height: 330px !important;
        }
      }

      .product-card__info.cross__prods {
        padding-top: 20px;

        .product-card__info_sub {
          flex-direction: column;
          align-items: flex-start;

          .product-card__name {
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
            height: auto;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
          }

          .product-card__prices {
            line-height: 27px;
            font-size: 20px;
            margin-top: 5px;
            align-items: center;
          }
        }

        .product-card-description {
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          line-height: 22px;
          margin: 15px 0;
        }
      }

      .cross-sell-button.cross__prods {
        .cross_btn {
          button {
            height: 42px;
            font-size: 16px;
            font-family: "Open Sans", sans-serif;
          }
        }

        .input-number {
          input {
            height: 42px;
          }
        }
      }

      .product-card__actions {
        display: none;
      }
    }
  }

  .no-thanks {
    margin-top: 40px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  }
}

.crossel_tittle__heading {
  text-align: center;
  margin: 20px 40px;
  font-size: 20px;
  line-height: 27px;
  padding: 0 15px 10px;
  border-bottom: 1px solid #E1E1E1;;
}

.no-thanks {
  margin-right: 40px;
  display: flex;
  justify-content: flex-end;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    filter: brightness(50%);
  }
}

.no-thanks.upsell {
  margin: 0;
}

.upsell-content {
  padding: 20px !important;

  hr {
    margin: 10px 0 20px;
  }

  .upsell_body {
    .product__content {
      .product__gallery {
        .product-image__img {
          max-height: 300px !important;
        }
      }

      .product__info {
        text-align: left;
        padding-top: 16.5px;

        h1 {
          text-align: left;
          margin: 5px 0;
        }

        .product__prices {
          margin: 0 0 15px;

          .product-card__new-price {
            .currency_value {
              font-size: 20px;
            }
          }

        }

        .short_description_block {
          .product-inner-description-title {
            font-size: 20px;
            margin-bottom: 5px;
            text-align: start;
          }
        }

        .product__meta {
          margin: 15px 0 10px;
          border: none;
          padding: 0;
        }
      }

      .product__sidebar {
        .product__options {
          .product__actions {
            gap: 15px !important;

            .no-thanks,
            button {
              font-family: "Open Sans", sans-serif;
            }
          }
        }
      }
    }
  }
}

.crossSell-title {
  div {
    border-radius: 10px 10px 0 0 !important;
    display: flex;
    height: 40px;
    background-color: #37c65d;
    color: #fff;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  span {
    padding-left: 10px;
  }
}

.modalss {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 202;
  transition: 0.5s;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.modalss.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.modal-content {
  background-color: white;
  max-width: 1001px;
  z-index: 101;
  transform: scale(0.5);
  transition: 0.4s all;
}

.active {
  text-align: center;
  transform: scale(1);
}

.modal-content.active.upsell {
  max-width: 720px !important;
  min-height: 398px !important;
  background: #ffffff !important;
  border-radius: 10px !important;
  padding: 20px !important;
  box-sizing: border-box !important;


}

.modal-content.active.crossSell,
.modal-content.active.crossel2 {
  max-width: 780px !important;
  min-height: 600px !important;
  background: #ffffff !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  padding: 0 0 40px;
}


@media screen and (max-width: 991px) {
  .modal-content.active.upsell {
    .upsell-content {
      .upsell_body {
        .product__content {
          .product__gallery {
            .product-image__img {
              max-height: 300px !important;
            }
          }
        }
      }
    }
  }

  .modal-content.active.register {
    min-height: 0;
  }
}

@media screen and (max-width: 767px) {
  .crossSell-content {
    .crossel_body {
      .product-card {
        .product-card__image.cross__prods {
          img {
            max-height: 300px !important;
            aspect-ratio: 1/1 !important;
          }
        }
      }
    }
  }

  .modal-content.active.upsell {
    padding: 15px 10px 20px !important;

    .upsell-content {
      .uPsell-title {
        font-size: 16px;
      }

      hr {
        margin: 10px 0;
      }

      .upsell_body {
        .product__content {
          .product__gallery {
            .product-image__img {
              max-height: 350px !important;
            }
          }

          .product__info {
            padding-top: 15px !important;

            .product__prices {
              margin: 0 0 10px;

              .product-card__new-price {
                .currency_value {
                  font-size: 18px;
                }
              }

            }

            .short_description_block {
              .product-inner-description-title {
                font-size: 18px;
              }

              p {
                font-size: 14px;
              }
            }

            .product__meta {
              margin: 10px 0 20px;
            }
          }

          .product__sidebar {
            justify-content: center;
          }
        }
      }
    }
  }

  //.modal-content {
  //  width: 95%;
  //}
}

@media screen and (max-width: 425px) {
  .crossSell-content {
    padding: 0 !important;

    .crossel_body {
      padding: 0 11px;
      gap: 11px;

      .product-card {

        .product-card__info.cross__prods {
          .product-card-description {
            display: none;
          }

          .product-card__info_sub {
            .product-card__name {
              font-size: 16px;
            }

            .product-card__prices {
              justify-content: flex-start;
              line-height: 1;
              font-size: 16px;
              height: 20px;
              margin: 10px 0 0;
            }
          }

          .product-card-description {
            font-size: 14px;
          }
        }
      }
    }

    .cross-sell-button {
      padding: 5px;
    }

    .no-thanks {
      margin-top: 40px;
    }
  }

  .crossel_tittle__heading {
    font-size: 16px;
    padding: 0 5px 10px;
    margin: 20px 10px 36px;
  }

  .crossel_body {
    padding: 0 10px;
    gap: 10px;

    .product-card {
      .product-card__image {
        .product-image__body {
          .product-image__img {
            max-height: 150px !important;
          }
        }
      }
    }
  }

  .no-thanks {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    text-decoration: underline;
    cursor: pointer;
    margin-right: 0;
    margin-top: 10px;
    justify-content: center;
  }

  .crossSell-title {
    div {
      padding: 13px;
    }
  }

  .crossel_success_message {
    font-size: 14px;
  }

  .modal-content.active.crossSell,
  .modal-content.active.crossel2 {
    min-height: 400px !important;
    padding: 0 0 20px;
  }

  .modal-content.active.upsell,
  .modal-content.active.crossSell .modal-content.active.crossel2 {
    min-height: 0 !important;
    max-width: initial !important;
  }

  .upsell-content {
    margin: 0;
  }
}

.product-card-description {
  font-size: 15px;
  height: 25px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 635px) {
  .cross-sell-button.cross__prods {
    flex-direction: column;
    gap: 16px;
  }

  .cross-sell-button.cross__prods {
    .product-inner-quantity {
      width: 100%;
    }
  }

  .cross_btn.cross__prods {
    width: 100%;
  }
}