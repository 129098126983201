@import "../../scss/variables";
@import "../../scss/functions";
@import "../../scss/mixins/hacks";
@import "../../scss/mixins/direction";

$local-item-height: 48px;
$local-expand-transition-duration: 0.3s;
$local-transition-duration: 0.3s;

.click-disabled {
  pointer-events: none;
}

.indicator--mobile {
  width: 100%;
}

.wishlist-icon-mobile {
  display: flex;
}

.mobile-header {
  position: sticky;
  top: 0;
  z-index: 99;
}

.mobile-header__panel {
  z-index: 10;
  position: relative;
  background: #fff;
  box-shadow: $nav-panel-shadow;
  color: $nav-panel-font-color;
}

.mobile-header__panel .mobile-header__menu-button svg {
  fill: black;
}

.mobile-header__menu-button {
  svg {
    fill: $main-theme-color-dark;
  }

  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: $mobile-header-menu-button-border-radius;
  background: $mobile-header-menu-button-default-bg;
  fill: $mobile-header-menu-button-default-color;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: fill 0.2s, background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background: $mobile-header-menu-button-hover-bg;
    fill: $mobile-header-menu-button-hover-color;
  }
}

.mobile-header__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $nav-panel-height;

  .header__logo {
    a {
      img {
        display: block;
        height: 40px !important;
        @media (max-width: 320px) {
          height: 20px !important;
        }
      }
    }

    span {
      position: relative !important;
      min-width: 200px !important;
      height: 35px !important;
    }
  }
}

.mobile-header__indicators {
  display: flex;
  align-items: center;
  @include direction {
    #{$margin-inline-start}: auto;
  }

  .indicator__button {
    fill: black;
  }
}

.mobile-header__panel {
  .account-logo-svg,
  .search-icon {
    path {
      fill: black !important;
    }
  }

  .cart-icon {
    path {
      fill: none;
    }
  }

  .indicator__area {
    path {
      fill: none;
    }
  }


  .language-adjust {
    padding-right: unset;
  }

  .topBar-dropdown__body {
    right: 0;
    padding-right: 10px;
  }

  .container {
    max-width: unset;
  }

  .topBar__row {
    max-width: 100%;
    display: flex !important;
  }
}

.mobile-links__item .mobile-links__item-link {
  padding: 8px 20px;
  line-height: 21px;
}

.mobile-links__item-title .mobile-links__item-link {
  font-size: 16px;
  padding: 7px 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.mobile-links__item-link {
  flex-grow: 1;
  color: $mobilemenu-font-color;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;

  @include direction {
    text-align: $inline-start;
  }

  &:focus {
    outline: none;
  }

  &,
  &:hover {
    color: inherit;
  }
}

.mobile-links--level--0 {
  & > li > .mobile-links__item {
    border-bottom: 1px solid $mobilemenu-divider-color;

    & > .mobile-links__item-title {
      height: $local-item-height;

      .mobile-links__item-link {
        padding: #{($local-item-height - 24px) / 2} 20px;
      }
    }
  }
}

.mobile-links--level--1 {
  font-size: 14px;
  line-height: 14px;

  .mobile-links__item .mobile-links__item-link {
    padding: #{(40px - 14px) / 2} 20px;
  }

  .mobile-links {
    background: $mobilemenu-bg;

    @include direction {
      #{$padding-inline-start}: 20px;
    }

    & > li {
      @include direction {
        #{$border-inline-start}: 1px solid $mobilemenu-divider-color;
      }
    }
  }

  li:last-child > .mobile-links__item {
    border-bottom: none;
  }
}

.mobile-links__item--open {
  & > .mobile-links__item-sub-links {
    visibility: visible;
    height: auto;
    opacity: 1;
    transition-delay: 0s, 0s, 0s;
  }

  & > .mobile-links__item-title .mobile-links__item-arrow {
    transform: rotateZ(180deg) translateY(1px);
  }
}

.mobile-links__item-toggle {
  border: none;
  background: transparent;
  padding: 0;
  width: 55px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: inherit;
  fill: $mobilemenu-font-muted-color;
  transition: fill 0.2s;

  @include direction {
    #{$border-inline-start}: 1px solid $mobilemenu-divider-color;
  }

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    cursor: inherit;
  }
}

.mobile-links__item-arrow {
  transition: transform $local-expand-transition-duration;
}

.mobile-links__item-sub-links {
  visibility: hidden;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height $local-expand-transition-duration,
  opacity $local-expand-transition-duration,
  visibility 0s $local-expand-transition-duration;
}

.mobile-links--level--0 > li > .mobile-links__item > .mobile-links__item-title {
  height: 60px;
}

.mobile-links__item {
  border-bottom: 1px solid $mobilemenu-divider-color;
}

.mobile-links__item-title {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  height: 60px;
}

.mobile-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-links:not(.mobile-links--level--0) {
  border-top: 1px solid $mobilemenu-divider-color;
}

.mobile-links--level--2 {
  margin-left: 10px;
}

.mobile-links--level--3 {
  margin-left: 20px;
}

.mobile-links--level--4 {
  margin-left: 30px;
}

.mobile-links--level--5 {
  margin-left: 40px;
}

.mobilemenu {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 60px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: visibility 0s $local-transition-duration;

  @include direction {
    #{$inset-inline-start}: 0;
  }
}

.mobilemenu--open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;

  .mobilemenu__backdrop {
    opacity: 0.9;
  }

  .mobilemenu__body {
    @include direction {
      transform: translateX(0);
    }
  }
}

.mobilemenu__backdrop {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: $mobilemenu-backdrop-bg;
  opacity: 0;
  transition: opacity $local-transition-duration;

  @include direction {
    #{$inset-inline-start}: 0;
  }
}

.mobilemenu__body {
  position: absolute;
  top: 0;
  width: 100%;
  background: $mobilemenu-bg;
  box-shadow: $mobilemenu-shadow;
  //box-shadow: none;
  transition: transform $local-transition-duration;
  display: flex;
  flex-direction: column;

  @include direction {
    #{$inset-inline-start}: 0;
    transform: translateX(calc(-100% * #{$transform-direction} - 30px * #{$transform-direction}));
  }
}

.mobilemenu__content {
  flex-grow: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin: 40px 10px 80px;
}

.bottom_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  z-index: 200;
  transition: ease-in-out .3s;

  .bottom_search {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $main-theme-color-light;
    box-shadow: 0 0 4px white;
    position: absolute;
    left: calc(50% - 32px);
    top: -32px;
    z-index: 9;

    .click-disabled {
      path {
        fill: white !important;
      }
    }

    @media screen and (max-width: 425px) {
      width: 64px;
      height: 64px;
      left: calc(50% - 32px);
      top: -32px;
    }

  }

}

@media screen and (max-width: 991px) {
  .mobile-links__item-title {
    justify-content: space-between;
    align-items: center;

    .link-label-fms {
      width: 100%;
      display: flex;
      text-align: left;
      align-items: center;

      svg {
        margin: auto 15px auto 5px;

        path {
          fill: #3d464d;
        }
      }
    }
  }

  .mobile-links--level--1 {
    .link-label-fms {
      width: 21px;
    }
  }

  .mobilemenu--open {
    overflow-y: scroll;
  }

  .mobilemenu__content {
    .mob-links__item {
      border-bottom: 1px solid #ebebeb;
    }
  }

  .mobile-links__blok {
    display: flex;
    gap: 15px;
    font-size: 16px;
    color: #3d464d;

    svg {
      margin: auto 0;

      path {
        fill: #3d464d;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .mobile-header__search {
    flex-grow: 1;
    height: 36px;
    @include direction {
      #{$margin-inline-end}: 8px;
    }
  }

  .mobile-header__body {
    .mobile-header__indicators {
      visibility: hidden;
    }
  }
}

@media screen and (max-width: 768px) {
  .mobile-header__search {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    background: $offcanvas-search-bg;
    box-shadow: $offcanvas-search-shadow;
    z-index: 1;
    transform: translateY(-54px);
    visibility: hidden;
    opacity: 0;
    transition: transform 0.3s ease-in-out, visibility 0s 0.3s, opacity 0.3s;
    display: flex;
    pointer-events: none;
    @include direction {
      #{$inset-inline-start}: 0;
    }
  }

  .mobile-header__search--open {
    pointer-events: auto;
    transition-delay: 0s, 0s;
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  .mobilemenu__content {
    margin: 0 10px 80px;
  }

  .mob-links__item {
    padding: 18px 5px;
    margin: 0;
  }
}

@media screen and (max-width: 425px) {
  .mobile-header__indicators .indicator__button {
    padding-right: 5px;
  }
}